
















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import VVerifyPasswordCard from "../VVerifyPasswordCard.vue";

import { confirm } from "@/helpers/dialogs";
import { CUSTOMER_URL } from "@/config";
import { menuEvents } from "@/constants/events";

import { MenuModel } from "@/@types/model";

@Component({ name: "VMenuCardActionsDropdown" })
export default class VMenuCardActionsDropdown extends Vue {
  CUSTOMER_URL: string = CUSTOMER_URL;
  isShowMore: boolean = false;

  @Prop(Object) readonly menu!: MenuModel;

  @Get("app/alternativeLanguages") alternativeLanguages!: string[];

  get isShowMoreForce() {
    return this.menu.archivedAt || this.menu.deletedAt;
  }

  showPreviewMenu() {
    this.$emitter.emit(menuEvents.SHOW_MENU_PREVIEW, this.menu.id);
  }

  showSettingsMenu() {
    this.$store.dispatch("menus/setMenu", this.menu.id);
    this.$emitter.emit(menuEvents.SHOW_MENU_SETTINGS, this.menu.id);
  }

  async duplicateMenu() {
    await this.$store.dispatch("menus/duplicateMenu", this.menu);
  }

  async archiveMenu() {
    const isConfirm = await this.$dialog.warning(confirm());
    if (!isConfirm) return;
    await this.$store.dispatch("menus/archiveMenu", this.menu);
  }

  async unArchiveMenu() {
    const isConfirm = await this.$dialog.warning(confirm());
    if (!isConfirm) return;
    await this.$store.dispatch("menus/unArchiveMenu", this.menu);
  }

  async trashMenu() {
    const isConfirm = await this.$dialog.warning(confirm());
    if (!isConfirm) return;
    await this.$store.dispatch("menus/trashMenu", this.menu);
  }

  async unTrashMenu() {
    const isConfirm = await this.$dialog.warning(confirm());
    if (!isConfirm) return;
    await this.$store.dispatch("menus/unTrashMenu", this.menu);
  }

  async deleteMenu() {
    const isValid = await this.$dialog.showAndWait(VVerifyPasswordCard, { showClose: false });
    if (!isValid) return;

    this.$store.dispatch("menus/deleteMenu", this.menu);
  }
}
