<template>
  <div class="menus-list-page">
    <div v-if="isTablet" class="menus-list-page__header">
      <v-spacer />
      <v-btn :to="{ name: 'menus.create' }" color="primary">
        <v-icon class="mr-1" size="18">mdi-plus</v-icon>
        {{ $t("Menu.AddNewMenu") }}
      </v-btn>
    </div>
    <v-menus-list :menus="menus" :loading="isMenusLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VMenusList from "../../components/menus/VMenusList";

export default {
  name: "MenusList",

  layout: "default",

  metaInfo() {
    return { title: this.$t("Menu.MyMenus") };
  },

  components: { VMenusList },

  computed: {
    ...mapGetters({
      menus: "menus/menus",
      isMenusLoading: "menus/isMenusLoading",
    }),
  },

  mounted() {
    this.$store.dispatch("menus/fetchMenus");
  },

  destroyed() {
    this.$store.dispatch("menus/clearMenus");
  },
};
</script>

<style lang="scss" scoped>
.menus-list-page {
  &__header {
    display: flex;
    margin-bottom: 20px;
  }
}
</style>
