<template>
  <div class="menus-list">
    <div class="menus-list-grid" v-if="menus.length">
      <div class="menus-list-col" v-if="isMenuListPage && !isTablet">
        <div class="create-card">
          <router-link
            :to="{ name: 'menus.create' }"
            class="d-flex flex-column align-center text-decoration-none"
          >
            <v-btn class="create-card__icon" color="primary" fab dark large>
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
            <span class="create-card__text text-uppercase mt-4">
              {{ $t("Menu.AddNew") }}
            </span>
          </router-link>
        </div>
      </div>
      <div class="menus-list-col" v-for="menu in menus" :key="menu.id">
        <v-menu-mobile-card v-if="isTablet" :menu="menu" />
        <v-menu-card v-if="!isTablet" :menu="menu" />
      </div>
    </div>
    <v-menu-cards-skeleton v-if="!isTablet && loading && !menus.length" />
    <v-menu-empty-data v-if="!loading && !menus.length" />

    <v-dialog
      v-model="isScheduleEdit"
      :width="isScheduleLoading ? '300px' : '768px'"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      persistent
    >
      <v-menu-schedule-editor-card
        v-if="isScheduleEditCard"
        @save="handleSaveSchedule"
        @cancel="handleCancelSchedule"
        @loading:start="isScheduleLoading = true"
        @loading:end="isScheduleLoading = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isSettingsEdit"
      max-width="768px"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
      persistent
    >
      <v-menu-settings-editor-card @save="handleSaveSettings" @cancel="handleCancelSettings" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VMenuCardsSkeleton from "./VMenuCardsSkeleton";
import VMenuCard from "./cards/VMenuCard";
import VMenuMobileCard from "./cards/VMenuMobileCard";
import VMenuEmptyData from "./VMenuEmptyData";
import VMenuSettingsEditorCard from "./cards/VMenuSettingsEditorCard";
import VMenuScheduleEditorCard from "./cards/VMenuScheduleEditorCard";

import { menuEvents } from "../../constants/events";

export default {
  name: "VMenusList",

  components: {
    VMenuCardsSkeleton,
    VMenuCard,
    VMenuMobileCard,
    VMenuEmptyData,
    VMenuSettingsEditorCard,
    VMenuScheduleEditorCard,
  },

  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isRefreshBusy: false,

      isScheduleEdit: false,
      isScheduleEditCard: false,
      isScheduleLoading: false,
      isSettingsEdit: false,

      scheduleTimeout: null,
    };
  },

  watch: {
    isScheduleEdit(value) {
      if (value) this.isScheduleEditCard = value;
      if (!value) {
        this.scheduleTimeout = setTimeout(() => {
          this.isScheduleEditCard = value;
        }, 150);
      }
    },
  },

  computed: {
    ...mapGetters({ menu: "menu/menu" }),
    isMenuListPage() {
      return this.$route.name === "menus";
    },
  },

  created() {
    this.$emitter.on(menuEvents.SHOW_MENU_SCHEDULE, () => {
      this.isScheduleEdit = true;
    });
    this.$emitter.on(menuEvents.SHOW_MENU_SETTINGS, () => {
      this.isSettingsEdit = true;
    });
  },

  methods: {
    handleSaveSchedule(schedule) {
      delete schedule.id;
      delete schedule.menuId;

      this.$store.dispatch("menus/updateMenu", { id: this.menu.id, payload: { schedule } });
      this.$store.dispatch("menu/clearMenu");
      this.isScheduleEdit = false;
    },

    handleCancelSchedule() {
      this.$store.dispatch("menu/clearMenu");
      this.isScheduleEdit = false;
    },

    handleSaveSettings(settings) {
      this.$store.dispatch("menus/updateMenu", { id: this.menu.id, payload: settings });
      this.$store.dispatch("menu/clearMenu");
      this.isSettingsEdit = false;
    },

    handleCancelSettings() {
      this.$store.dispatch("menu/clearMenu");
      this.isSettingsEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menus-list-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

.create-card {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px dashed $primary-color;
  padding: 30px;
  &__text {
    font-weight: bold;
  }
}

@media screen and (max-width: 1600px) {
  .menus-list-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 991px) {
  .menus-list-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .menus-list-grid {
    grid-template-columns: 100%;
  }
}
</style>
