<template>
  <div class="menu-empty-data">
    <p>{{ $t("Messages.Empty") }}</p>
    <v-btn v-if="$route.name === 'menus'" color="primary" :to="{ name: 'menus.create' }">
      + Add new menu
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "VMenuEmptyData",
};
</script>

<style lang="scss" scoped>
.menu-empty-data {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
