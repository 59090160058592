var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"menu-card",class:{
    'menu-card--published': _vm.menu.isPublished,
    'menu-card--unpublished': !_vm.menu.isPublished,
    'menu-card--enabled-order': _vm.menu.isEnabledToOrder,
    'menu-card--unenabled-order': !_vm.menu.isEnabledToOrder,
  }},[_c('v-card-title',{staticClass:"menu-card__header"},[_c('h2',{staticClass:"menu-card__title"},[_vm._v(_vm._s(_vm.menu.title[_vm.defaultLanguage]))]),_c('div',{staticClass:"menu-card__actions"},[_c('v-tooltip',{attrs:{"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [(_vm.menu.priceCurrency !== _vm.defaultCurrency)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-currency-usd ")])],1):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Menu.CurrencyChangedWarningHelper"))+" ")])]),_c('v-menu-card-actions-dropdown',{key:_vm.menu.id,attrs:{"menu":_vm.menu}})],1)]),_c('v-img-extend',{staticClass:"menu-card__img",class:{ 'menu-card__img--off': !_vm.menu.isPublished },attrs:{"aspect-ratio":"1.78","img":_vm.menu.photo,"size":"lg"}}),_c('v-card-text',{staticClass:"pt-2"},[_c('div',{staticClass:"status-and-enabled-order"},[_c('div',{staticClass:"menu-status"},[_c('v-switch',{staticClass:"menu-status__switch",attrs:{"input-value":_vm.menu.isPublished,"loading":_vm.isStatusLoading,"hide-details":"","inset":"","dense":""},on:{"change":_vm.handleChangeStatusMenu}}),_c('span',{staticClass:"menu-status__label"},[_vm._v(" "+_vm._s(_vm.menu.isPublished ? _vm.$t("Menu.Published") : _vm.$t("Menu.Unpublished"))+" ")]),_c('v-tooltip',{attrs:{"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Menu.PublishedHelper"))+" ")])])],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"menu-enabled-order"},[_c('v-switch',{staticClass:"menu-enabled-order__switch",attrs:{"input-value":_vm.menu.isEnabledToOrder,"disabled":!_vm.menu.isPublished,"loading":_vm.isEnabledToOrderLoading,"hide-details":"","inset":"","dense":""},on:{"change":_vm.handleChangeEnabledOrderMenu}}),_c('span',{staticClass:"menu-status__label"},[_vm._v(" "+_vm._s(_vm.menu.isEnabledToOrder ? _vm.$t("Menu.EnabledToOrder") : _vm.$t("Menu.DisabledToOrder"))+" ")]),_c('v-tooltip',{attrs:{"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Menu.EnableToOrderHelper"))+" ")])])],1),_c('v-menu',{attrs:{"min-width":_vm.menu.schedule ? 300 : undefined,"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.menu.isPublished || !_vm.menu.isEnabledToOrder,"icon":""},on:{"click":_vm.showScheduleMenu}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-calendar-clock")])],1)]}}])},[_c('v-menu-schedule-tooltip-card',{attrs:{"schedule":_vm.menu.schedule}})],1)],1)]),_c('v-divider',{staticClass:"mt-1 mb-2"}),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(_vm.menu.categories.length)+" "+_vm._s(_vm.$t("Menu.Categories"))+" • "+_vm._s(_vm.dishesNumber)+" "+_vm._s(_vm.$t("Menu.Items"))+" ")]),_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(_vm.menu.createdAt,_vm.locale)))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }