<template>
  <div class="menu-skeleton-grid">
    <div class="menu-skeleton-col" v-for="number in numberCards" :key="number">
      <v-skeleton-loader type="image, article, actions"></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
export default {
  name: "VMenuCardsSkeleton",

  props: {
    numberCards: {
      type: [Number, String],
      default: 4,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-skeleton-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

@media screen and (max-width: 1600px) {
  .menu-skeleton-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
