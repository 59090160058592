<template>
  <v-card
    class="menu-card"
    :class="{
      'menu-card--published': menu.isPublished,
      'menu-card--unpublished': !menu.isPublished,
      'menu-card--enabled-order': menu.isEnabledToOrder,
      'menu-card--unenabled-order': !menu.isEnabledToOrder,
    }"
  >
    <v-card-title class="menu-card__header">
      <h2 class="menu-card__title">{{ menu.title[defaultLanguage] }}</h2>
      <div class="menu-card__actions">
        <v-tooltip max-width="300" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="menu.priceCurrency !== defaultCurrency"
              v-bind="attrs"
              v-on="on"
              color="error"
              icon
            >
              <v-icon> mdi-currency-usd </v-icon>
            </v-btn>
          </template>
          <span> {{ $t("Menu.CurrencyChangedWarningHelper") }} </span>
        </v-tooltip>

        <v-menu-card-actions-dropdown :menu="menu" :key="menu.id" />
      </div>
    </v-card-title>

    <v-img-extend
      class="menu-card__img"
      :class="{ 'menu-card__img--off': !menu.isPublished }"
      aspect-ratio="1.78"
      :img="menu.photo"
      size="lg"
    />

    <v-card-text class="pt-2">
      <div class="status-and-enabled-order">
        <div class="menu-status">
          <v-switch
            class="menu-status__switch"
            :input-value="menu.isPublished"
            @change="handleChangeStatusMenu"
            :loading="isStatusLoading"
            hide-details
            inset
            dense
          ></v-switch>
          <span class="menu-status__label">
            {{ menu.isPublished ? $t("Menu.Published") : $t("Menu.Unpublished") }}
          </span>
          <v-tooltip max-width="300" right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="grey" v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
            </template>
            <span> {{ $t("Menu.PublishedHelper") }} </span>
          </v-tooltip>
        </div>
        <div class="d-flex justify-space-between">
          <div class="menu-enabled-order">
            <v-switch
              class="menu-enabled-order__switch"
              :input-value="menu.isEnabledToOrder"
              @change="handleChangeEnabledOrderMenu"
              :disabled="!menu.isPublished"
              :loading="isEnabledToOrderLoading"
              hide-details
              inset
              dense
            ></v-switch>
            <span class="menu-status__label">
              {{ menu.isEnabledToOrder ? $t("Menu.EnabledToOrder") : $t("Menu.DisabledToOrder") }}
            </span>
            <v-tooltip max-width="300" right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="grey" v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
              </template>
              <span> {{ $t("Menu.EnableToOrderHelper") }} </span>
            </v-tooltip>
          </div>
          <v-menu :min-width="menu.schedule ? 300 : undefined" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="showScheduleMenu"
                :disabled="!menu.isPublished || !menu.isEnabledToOrder"
                icon
              >
                <v-icon color="primary">mdi-calendar-clock</v-icon>
              </v-btn>
            </template>
            <v-menu-schedule-tooltip-card :schedule="menu.schedule" />
          </v-menu>
        </div>
      </div>

      <v-divider class="mt-1 mb-2"></v-divider>

      <div class="d-flex justify-space-between align-center">
        <span class="text-lowercase">
          {{ menu.categories.length }} {{ $t("Menu.Categories") }} &bull; {{ dishesNumber }}
          {{ $t("Menu.Items") }}
        </span>
        <span>{{ menu.createdAt | dateFormat(locale) }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import VMenuScheduleTooltipCard from "./VMenuScheduleTooltipCard";
import VMenuCardActionsDropdown from "../VMenuCardActionsDropdown";

import { API_URL } from "../../../config";

import { menuEvents } from "../../../constants/events";

export default {
  name: "VMenuCard",

  props: ["menu"],

  components: {
    VMenuScheduleTooltipCard,
    VMenuCardActionsDropdown,
  },

  data() {
    return {
      API_URL,

      isStatusLoading: false,
      isEnabledToOrderLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      defaultLanguage: "app/defaultLanguage",
      defaultCurrency: "app/defaultCurrency",
      locale: "lang/locale",
    }),

    isMenuPage() {
      return this.$route.name === "menus";
    },

    dishesNumber() {
      return this.menu.categories.reduce((acc, curr) => acc + curr.dishes.length, 0);
    },
  },

  methods: {
    ...mapActions({
      toggleStatusMenu: "menus/toggleStatusMenu",
      toggleEnabledOrderMenu: "menus/toggleEnabledOrderMenu",
    }),

    showScheduleMenu() {
      this.$store.dispatch("menus/setMenu", this.menu.id);
      this.$emitter.emit(menuEvents.SHOW_MENU_SCHEDULE, this.menu.id);
    },

    async handleChangeStatusMenu(isPublished) {
      this.isStatusLoading = true;
      await this.toggleStatusMenu({
        id: this.menu.id,
        isPublished,
        isEnabledToOrder: this.menu.isEnabledToOrder,
      });
      this.isStatusLoading = false;
    },

    async handleChangeEnabledOrderMenu(isEnabledToOrder) {
      this.isEnabledToOrderLoading = true;
      await this.toggleEnabledOrderMenu({ id: this.menu.id, isEnabledToOrder });
      this.isEnabledToOrderLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-card {
  &--published {
    .menu-status {
      color: #4caf50;
    }
  }
  &--unpublished {
    background-color: #eee;
    .menu-status {
      color: #ff9800;
    }
  }
  &--enabled-order {
    .menu-enabled-order {
      color: #4caf50;
    }
  }
  &--unenabled-order {
    .menu-enabled-order {
      color: #ff9800;
    }
  }
  &__header {
    padding-right: 50px;
  }
  &__title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__img {
    &--off {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(238, 238, 238, 0.7);
      }
    }
  }
  &__actions {
    position: absolute;
    top: 10px;
    right: 5px;
  }
}

.menu-status,
.menu-enabled-order {
  display: flex;
  align-items: center;
  &__switch {
    margin-top: 0;
    padding-top: 0;
  }
  &__label {
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 500;
    margin-left: -5px;
    margin-right: 5px;
    text-transform: uppercase;
  }
}

.v-application.theme--dark {
  .menu-card {
    &--unpublished {
      background-color: #3f3f3f;
    }
  }
}
</style>
