<template>
  <v-card class="tooltip-schedule-card">
    <v-card-text class="tooltip-schedule-body">
      <ul v-if="schedule" class="tooltip-schedule-list">
        <li class="tooltip-schedule-item" v-for="{ day, status, timeWindows } in days" :key="day">
          <v-icon class="tooltip-schedule-indicator" size="12" :color="TYPE_COLOR[status]">
            mdi-circle
          </v-icon>
          <b>{{ weekDays[day] }}</b>
          <div
            class="tooltip-schedule-time-windows"
            v-if="status === 'SPECIFIC' && timeWindows.length"
          >
            <span v-for="(timeWindow, idx) in timeWindows" :key="idx">
              {{ `${timeWindow.start} - ${timeWindow.end}` }}
            </span>
          </div>
        </li>
      </ul>
      <span v-else>Always active</span>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

const TYPE_COLOR = {
  SHOW_ALL: "green",
  HIDE_ALL: "orange",
  SPECIFIC: "blue",
};

export default {
  name: "VMenuScheduleTooltipCard",

  props: ["schedule"],

  data() {
    return {
      TYPE_COLOR,
    };
  },

  computed: {
    ...mapGetters({
      appSettings: "app/settings",
    }),
    days() {
      const firstDayIndex = this.schedule.days.findIndex(
        ({ dayCode }) => dayCode === this.appSettings.firstDayOfWeek,
      );
      return this.schedule.days
        .slice(firstDayIndex)
        .concat(this.schedule.days.slice(0, firstDayIndex));
    },
    weekDays() {
      return moment.weekdays();
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-schedule-body {
  padding: 10px;
}

.tooltip-schedule-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 12px;
}

.tooltip-schedule-item {
  display: flex;
  align-items: center;
}

.tooltip-schedule-indicator {
  margin-right: 8px;
}

.tooltip-schedule-time-windows {
  display: flex;
  align-items: center;
  margin-left: 5px;
  &:before {
    content: "•";
  }
  span {
    display: block;
    margin-left: 5px;
    &:after {
      content: ",";
    }
    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}
</style>