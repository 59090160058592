<template>
  <v-card
    class="menu-card"
    :class="{
      'menu-card--published': menu.isPublished,
      'menu-card--unpublished': !menu.isPublished,
      'menu-card--enabled-order': menu.isEnabledToOrder,
      'menu-card--unenabled-order': !menu.isEnabledToOrder,
    }"
  >
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="status-and-enabled-order">
          <div class="menu-status">
            <v-switch
              class="menu-status__switch"
              :input-value="menu.isPublished"
              @change="handleChangeStatusMenu"
              :loading="isStatusLoading"
              :ripple="false"
              hide-details
              inset
            ></v-switch>
            <span class="menu-status__label">
              {{ menu.isPublished ? $t("Menu.Published") : $t("Menu.Unpublished") }}
            </span>
          </div>
          <div class="menu-enabled-order">
            <v-switch
              class="menu-enabled-order__switch"
              :input-value="menu.isEnabledToOrder"
              @change="handleChangeEnabledOrderMenu"
              :disabled="!menu.isPublished"
              :loading="isEnabledToOrderLoading"
              :ripple="false"
              hide-details
              inset
            ></v-switch>
            <span class="menu-status__label">
              {{ menu.isEnabledToOrder ? $t("Menu.EnabledToOrder") : $t("Menu.DisabledToOrder") }}
            </span>
          </div>
        </div>
        <v-avatar v-if="menu.photo" class="rounded" size="80" color="grey" tile>
          <v-img-extend :img="menu.photo" fallback-size="sm" size="sm" />
        </v-avatar>
      </div>
      <div :style="{ height: '50px' }" class="mt-4">
        <v-list-item-title class="headline">
          {{ menu.title[defaultLanguage] }}
        </v-list-item-title>
        <v-list-item-title> {{ menu.internalComment[defaultLanguage] }}</v-list-item-title>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text>
      <div class="d-flex justify-space-between align-center">
        <span class="text-lowercase">
          {{ menu.categories.length }} {{ $t("Menu.Categories") }} &bull; {{ dishesNumber }}
          {{ $t("Menu.Items") }}
        </span>
        <span>{{ menu.createdAt | dateFormat(locale) }}</span>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="teal white--text"
        :to="{ name: 'menus.edit', params: { menuId: menu.id } }"
        text
      >
        {{ $t("GlobalUI.Edit") }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-menu-card-actions-dropdown :menu="menu" :key="menu.id" @click="handleClickAction" />
    </v-card-actions>
  </v-card>
</template>

<script>
import VMenuCardActionsDropdown from "../VMenuCardActionsDropdown";

import PREVIEW_IMAGE from "../../../assets/qr-menu-preview.jpg";

import { API_URL } from "./../../../config";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VMenuMobileCard",

  props: ["menu"],

  components: {
    VMenuCardActionsDropdown,
  },

  data() {
    return {
      PREVIEW_IMAGE,
      API_URL,

      isStatusLoading: false,
      isEnabledToOrderLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      defaultLanguage: "app/defaultLanguage",
      locale: "lang/locale",
    }),
    dishesNumber() {
      return this.menu.categories.reduce((acc, curr) => acc + curr.dishes.length, 0);
    },
  },

  methods: {
    ...mapActions({
      toggleStatusMenu: "menus/toggleStatusMenu",
      toggleEnabledOrderMenu: "menus/toggleEnabledOrderMenu",
    }),

    handleClickAction(type) {
      this.$emit("action", type);
    },

    async handleChangeStatusMenu(isPublished) {
      this.isStatusLoading = true;
      await this.toggleStatusMenu({
        id: this.menu.id,
        isPublished,
        isEnabledToOrder: this.menu.isEnabledToOrder,
      });
      this.isStatusLoading = false;
    },
    async handleChangeEnabledOrderMenu(isEnabledToOrder) {
      this.isEnabledToOrderLoading = true;
      await this.toggleEnabledOrderMenu({ id: this.menu.id, isEnabledToOrder });
      this.isEnabledToOrderLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-card {
  &--published {
    .menu-status {
      color: #4caf50;
    }
  }
  &--unpublished {
    background-color: #eee;
    .menu-status {
      color: #ff9800;
    }
  }
  &--enabled-order {
    .menu-enabled-order {
      color: #4caf50;
    }
  }
  &--unenabled-order {
    .menu-enabled-order {
      color: #ff9800;
    }
  }
}

.status-and-enabled-order {
  margin-left: 5px;
  display: grid;
  gap: 10px;
}
.menu-status,
.menu-enabled-order {
  display: flex;
  align-items: center;
  &__switch {
    margin-top: 0;
    padding-top: 0;
  }
  &__label {
    font-size: 1em;
    letter-spacing: 1px;
    font-weight: 500;
    margin-left: -5px;
    text-transform: uppercase;
    opacity: 0.8;
  }
}

.v-application.theme--dark {
  .menu-card {
    &--unpublished {
      background-color: #3f3f3f;
    }
  }
}
</style>
